import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import './Contact.css';
import ContactForm from '../../components/contact/ContactForm';

export default function Contact() {
    const { t } = useTranslation();
    return (
        <>
        <Helmet>
            <title>FitFull - {t('navigation.contact')}</title>
        </Helmet>
        <section className="sectionWithDefaultPadding" id="contactSection">
            <div id="contactContent">
                <h2>{t('contact.pageTitle')}</h2>                
                <p id="description">{t('contact.pageDescription')}</p>
                <div>
                    <h4>{t('default.emailaddress')}:</h4>
                    <h4 href="mailto:support@fitfull.ch">support@fitfull.ch</h4>
                </div>
            </div>
            <ContactForm />
        </section>
        </>
        
    );
}