import { useTranslation } from 'react-i18next';
import './About.css';
import arrowsDown from '../../assets/icons/arrows_down.svg';
import security from '../../assets/images/security3D.png';
import portraitVideo from '../../assets/videos/FitFull_prelaunch_video_small.mp4';
import portraitVideoThumbnail from '../../assets/videos/FitFull_video_thumbnail.png';

import { Helmet } from 'react-helmet-async';

export default function About() {
        const { t } = useTranslation();

    return (
        <>
        <Helmet>
            <title>FitFull - {t('navigation.about')}</title>
        </Helmet>
        <section id="aboutUsLandingPageSection" className='blackBackground'>
            <h1 className='white'>{t('about.pageTitle')}</h1>
            <p className='textOnBlackSection'>{t('about.pageDescription')}</p>
            <a href="#aboutUsImages"><img src={arrowsDown} alt="arrow down"/></a>
        </section>
        <section id='aboutUsImages'>
            <video controls poster={portraitVideoThumbnail}>
                <source src={portraitVideo} type="video/mp4" />
            </video>            
        </section>
        <section id="aboutUsYearsSection">
            <div>
                <h1>2022</h1>
                <p>{t('about.2022.description')}</p>
            </div>
            <div className='dots'>
                <span>&#8226;</span>
                <span>&#8226;</span>
                <span>&#8226;</span>
            </div>
            <div>
                <h1>2023/2024</h1>
                <p>{t('about.2023.description')}</p>
            </div>
            <div className='dots'>
                <span>&#8226;</span>
                <span>&#8226;</span>
                <span>&#8226;</span>
            </div>
            <div>
                <h1>{t('about.future')}</h1>
                <p>{t('about.2024.description')}</p>
            </div>
        </section>
        <section className='blackBackground' id="aboutUsSecuritySection">
            <div id='aboutUsSecurityText'>
                <h2 className='white'>{t('about.security.title')}</h2>
                <p className='textOnBlackSection'>{t('about.security.description')}</p>
            </div>
            <img src={security} alt="secure app"/>
        </section>
        <section id='aboutUsFeedbackSection'>
            <h2>{t('about.feedback.title')}</h2>
            <p id="feedbackDescription">{t('about.feedback.description')}</p>
            <div>
                <p style={{fontWeight: "bold"}}>{t('default.emailaddress')}:</p>
                <a href="mailto:support@fitfull.ch">support@fitfull.ch</a>
            </div>
            {/**<form action="">
                <div className='inputDiv'>
                    <label htmlFor="">{t('default.name')}</label>
                    <input type="text" placeholder={t('default.name')}/>
                </div>

                <div className='inputDiv'>
                    <label htmlFor="">{t('default.emailaddress')}</label>
                    <input type="text" placeholder={t('default.emailaddress')}/>
                </div>

                <div className='inputDiv'>
                    <label htmlFor="">{t('default.message')}</label>
                    <textarea  name="" id="" width="100%" placeholder={t('default.message')}></textarea>
                </div>

                <input type="submit" value={t('default.send')}/>

            </form> */}
        </section>
        </>

    )
}