import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './HamburgerMenu.css';

export default function HamburgerMenu() {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };


      
    

     


    return (
        <>
            <button className={`hamburgerButton ${menuOpen ? 'open' : ''}`} onClick={handleToggleMenu}>
                <span className="hamburgerLine"></span>
                <span className="hamburgerLine"></span>
                <span className="hamburgerLine"></span>
            </button>
            {menuOpen && (
                <>
                <div className='menuContent'>
                    <div className="menuItems">
                        {/**<a href="/features">{t('navigation.features')}</a> */}
                        <a href="/">{t('navigation.home')}</a>
                        <a href="/pricing">{t('navigation.pricing')}</a>
                        <a href="/faq">{t('navigation.faq')}</a>
                        <a href="/about">{t('navigation.aboutus')}</a>
                        <a href="/contact">{t('navigation.contact')}</a>
                    </div>
                    <div className="navButton">
                        <a className="defaultButton darkButton" href='https://app.fitfull.ch/login'>
                        {t('default.signin')}
                        </a>
                        <a className="defaultButton blueButton" href='https://app.fitfull.ch/register'>
                        {t('default.register')}
                        </a>
                    </div>
                </div>               
                </>
                
            )}
        </>
    );
}
