import React, { useEffect, useState } from 'react'
import './ContactForm.css';
import { useTranslation } from 'react-i18next';
import emailjs from "@emailjs/browser";
import { GoAlertFill } from 'react-icons/go';
import { FaHeart } from 'react-icons/fa';
import { Oval } from 'react-loader-spinner';

const ContactForm = () => {

    useEffect(() => emailjs.init("ZC_ArAKBFUwyRtCSR"), []);


    const [email, setEmail]  = useState('')
    const [name, setName]  = useState('')
    const [error, setError]  = useState('')
    const [successfull, setSuccessfull]  = useState('')
    const [loading, setLoading]  = useState(false)
    const [message, setMessage]  = useState('')
    const { t } = useTranslation();

    const handleSubmit = async (e) => {        
        e.preventDefault();
        setError('')
        setSuccessfull('')
        if (!name || !email || !message) {
            setError(t("contact.fill-out-all-fields"));
            return;
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
            setError(t("contact.email-wrong-format"))
            return;
        }
        else {
            const serviceId = "service_t9mfd8w";
            const templateId = "template_rwvbvx9";
            try {
            setLoading(true);
            await emailjs.send(serviceId, templateId, {
                from_name: name,
                email: email,
                message: message
            });
            } catch (error) {
                console.log(error)
                setError(t("contact.error-sending-email"))
            } finally {
                setLoading(false);
                setSuccessfull(t("contact.email-successfully-send"));                
            }
        }
      };
    

    return (
        <form  onSubmit={handleSubmit} className='contact_form'>
            
            <div className='contact_form_input_container'>
                <p>{t("default.name")}*</p>
                <input type="text" onChange={(e) => setName(e.target.value)} placeholder={t("default.name")}/>
            </div>
            <div className='contact_form_input_container'>
                <p>{t("default.emailaddress")}*</p>
                <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder={t("default.emailaddress")}/>
            </div>
            <div className='contact_form_input_container'>
                <p>{t("default.message")}*</p>
                <textarea type="email" onChange={(e) => setMessage(e.target.value)} placeholder={t("default.message")}/>
            </div>
            {error && 
                <div className='error_container'>
                    <GoAlertFill color='var(--red_main)' size={20} style={{minWidth: "20px"}}/>
                    <p>{error}</p>
                </div>
            }
            {successfull && 
                <div className='error_container'>
                    <FaHeart color='var(--green_main)' size={20} style={{minWidth: "20px"}}/>
                    <p>{successfull}</p>
                </div>
            }
            <button type='submit' className='defaultButton blueButton centeredButton'>
                {loading ?
                    <Oval
                        height={20}
                        width={20}
                        color="var(--white)"
                        wrapperStyle={{}}
                        wrapperclassName=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="var(--blue_transparent)"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                  />
                : t("default.send")
                }
                
            </button>
        </form>
    )
}

export default ContactForm