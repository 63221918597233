import { useTranslation } from "react-i18next";

export default function Imprint() {
    const { t } = useTranslation();
    return (
        <>
        <section className="sectionWithDefaultPadding data_screen">
            <h3>{t('navigation.imprint')}</h3>
            <div>
                <h5>{t('imprint.postal_address')}:</h5>
                <p>FitFull KLG</p>
                <p>Sagastrass 2, 7214 Grüsch</p>
            </div>
            <div>
                <h5>{t('imprint.contact')}:</h5>
                <p>support@fitfull.ch</p>
            </div>
            <div>
                <h5>{t('imprint.responsible_for_content')}:</h5>
                <p>Noah Ediz, Gesellschafter</p>
                <p>Lori Schoch, Gesellschafter</p>
            </div>
            <div>
                <p>{t('imprint.register_number')}: CHE-166.633.952</p>
                <a className='link_underline' href="https://www.uid.admin.ch/Detail.aspx?uid_id=CHE166633952">https://www.uid.admin.ch/Detail.aspx?uid_id=CHE166633952</a>
            </div>
            
        </section>

        </>
    )
}