import { useTranslation } from 'react-i18next';
import './Pricing.css';
import check from '../../assets/icons/check.svg'
import idea from '../../assets/icons/idea.svg'
import { Helmet } from 'react-helmet-async';
import { IoSparkles } from 'react-icons/io5';


export default function Pricing() {
    const { t } = useTranslation();
    return (
        <>
        <Helmet>
            <title>FitFull - {t('navigation.pricing')}</title>
        </Helmet>
        <section className="sectionWithDefaultPadding centeredSection" id='pricingSection'>
            <div className='pageTitle'>
                <h2>{t('pricing.title')}</h2>
                <h4>{t('pricing.description')}</h4>
            </div>
            <div id="pricingPlans">
                <div className='plan_container'>
                    <div className='plan'>
                        <div className='planDescription'>
                            <h4 className='planTitle'>{t('pricing.athlete.name')}</h4>
                            <h3>{t('pricing.athlete.price')}</h3>
                            <p>{t('pricing.athlete.description')}</p>
                        </div>
                        <div className='planIncludes'>
                            <p>{t('default.includes')}</p>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.athlete.feature01')}</p>
                            </div>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.athlete.feature02')}</p>
                            </div>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.athlete.feature03')}</p>
                            </div>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.athlete.feature04')}</p>
                            </div>                        
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.athlete.feature06')}</p>
                            </div>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.athlete.feature05')}</p>
                            </div>                        
                            <div className='planIncludes comingSoon'>
                                <p>{t('default.coming-soon')}</p>
                                
                                <div className='includedFeatures'>
                                    <img src={idea} alt="Idea" />
                                    <p>{t('pricing.athlete.feature07')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="defaultButton" href='https://app.fitfull.ch/register?plan=athlete'>
                        {t('default.select')}
                    </a>
                </div>
                
                <div className='plan_container'>
                    <div className='plan'>
                        <div className='planDescription'>
                            <h4 className='planTitle'>{t('pricing.coach.name')}</h4>
                            <h3>{t('pricing.coach.price')}</h3>
                            <p>{t('pricing.coach.description')}</p>   
                        </div>       
                        <div className='planIncludes'>
                            <p>{t('default.includes')}</p>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.coach.feature01')}</p>
                            </div>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.coach.feature02')}</p>
                            </div>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.coach.feature03')}</p>
                            </div>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.coach.feature04')}</p>
                            </div>
                            <p><i>{t('pricing.coach.info')}</i></p>
                        </div>
                    </div>
                    <a className="defaultButton" href='https://app.fitfull.ch/register?plan=coach'>
                        {t('default.select')}
                    </a>
                </div>
                
                <div className='plan_container'>
                    <div className='plan'>
                        <div className='planDescription'>
                            <div className='planTitleContainer'>
                                 <h4 className='planTitle'>{t('pricing.club.name')}</h4>
                                 <div className='recommended'>
                                    <IoSparkles color='var(--white)' size={14}/>
                                    <p>{t('default.recommended')}</p>
                                 </div>
                            </div>
                            
                            <h3>{t('pricing.club.price')}</h3>
                            <p>{t('pricing.club.description')}</p>
                        </div>

                        <div className='planIncludes'>
                            <p>{t('default.includes')}</p>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.club.feature01')}</p>
                            </div>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.club.feature03')}</p>
                            </div>
                            <div className='includedFeatures'>
                                <img src={check} alt="check" />
                                <p>{t('pricing.club.feature02')}</p>
                            </div>
                            <div className='pricing_club_table'>
                                <div>
                                    <p>{t('pricing.club.pricing_per_athlete_title')}</p>
                                    <p>{t('pricing.club.pricing_per_athlete')}</p>
                                </div>
                                <div>
                                    <p>{t('pricing.club.pricing_per_coach_title')}</p>
                                    <p>{t('pricing.club.pricing_per_coach')}</p>
                                </div>
                                <div>
                                    <p>{t('pricing.club.pricing_per_team_title')}</p>
                                    <p>{t('pricing.club.pricing_per_team')}</p>
                                </div>
                                <div>
                                    <p>{t('pricing.club.pricing_flat_rate_title')}</p>
                                    <p>{t('pricing.club.pricing_flat_rate')}</p>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <a className="defaultButton blueButton" href='https://app.fitfull.ch/register?plan=club'>
                        {t('default.select')}
                    </a>
                </div>
            </div>            
        </section>
        </>
        
    )
}