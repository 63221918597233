import { useTranslation } from "react-i18next";

export default function Agbs() {
    const { t } = useTranslation();
    return (
        <>
        <section className="sectionWithDefaultPadding data_screen">
            <h3>{t('navigation.agbs')}</h3>
            <p>{t('data_privacy_policy.last_change')}: 25.02.2024</p>
            <div className='data_rights_container'>
                <h4>{t('agbs.title01')}:</h4>
                <p>{t('agbs.description01')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>{t('agbs.title02')}:</h4>
                <p>{t('agbs.description02')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>{t('agbs.title03')}:</h4>
                <p>{t('agbs.description03_01')}</p>
                <p>{t('agbs.description03_02')}</p>
                <p>{t('agbs.description03_03')}</p>
            </div>   
            <div className='data_rights_container'>
                <h4>{t('agbs.title04')}:</h4>
                <p>{t('agbs.description04_01')}</p>
                <p>{t('agbs.description04_02')}</p>
                <p>{t('agbs.description04_03')}</p>
                <p>{t('agbs.description04_04')}</p>
                <p>{t('agbs.description04_05')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>{t('agbs.title05')}:</h4>
                <p>{t('agbs.description05')}</p>
                <p>{t('agbs.description05_01')}</p>
                <p>{t('agbs.description05_02')}</p>
                <p>{t('agbs.description05_03')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>{t('agbs.title06')}:</h4>
                <p>{t('agbs.description06')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>{t('agbs.title07')}:</h4>
                <p>{t('agbs.description07_01')}</p>
                <p>{t('agbs.description07_02')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>{t('agbs.title08')}:</h4>
                <p>{t('agbs.description08_01')}</p>
                <p>{t('agbs.description08_02')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>{t('agbs.title10')}:</h4>
                <p>{t('agbs.description10')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>{t('agbs.title11')}:</h4>
                <p>{t('agbs.description11')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>{t('agbs.title12')}:</h4>
                <p>{t('agbs.description12')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>{t('agbs.title13')}:</h4>
                <p>{t('agbs.description13')}</p>
            </div>
            
        </section>

        </>
    )
}