import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Faq.css';
import dropdownOpen from '../../assets/icons/dropdown_open.svg';
import { Helmet } from 'react-helmet-async';

export default function Faq() {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const faqsData = [
      {
          "id": 1,
          "question": t('faq.01_question'),
          "answer": t('faq.01_answer'),
          "isOpen": false
      },
      {
          "id": 2,
          "question": t('faq.02_question'),
          "answer": t('faq.02_answer'),
          "isOpen": false
      },
      {
          "id": 3,
          "question": t('faq.03_question'),
          "answer": t('faq.03_answer'),
          "isOpen": false
      },
      {
          "id": 4,
          "question": t('faq.04_question'),
          "answer": t('faq.04_answer'),
          "isOpen": false
      },
      {
          "id": 5,
          "question": t('faq.05_question'),
          "answer": t('faq.05_answer'),
          "isOpen": false
      },
      {
          "id": 6,
          "question": t('faq.06_question'),
          "answer": t('faq.06_answer'),
          "isOpen": false
      },
      {
          "id": 7,
          "question": t('faq.07_question'),
          "answer": t('faq.07_answer'),
          "isOpen": false
      },
      /**{
          "id": 8,
          "question": t('faq.08_question'),
          "answer": t('faq.08_answer'),
          "isOpen": false
      }, */
      {
          "id": 9,
          "question": t('faq.09_question'),
          "answer": t('faq.09_answer'),
          "isOpen": false
      },
      {
          "id": 10,
          "question": t('faq.10_question'),
          "answer": t('faq.10_answer'),
          "isOpen": false
      }
    ]
  

    setFaqs(faqsData);
  }, [t]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFaqClick = (id) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq) =>
        faq.id === id ? { ...faq, isOpen: !faq.isOpen } : faq
      )
    );
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const getSearchScore = (faq, searchTerm) => {
    const questionScore = faq.question.toLowerCase().includes(searchTerm) ? 1 : 0;
    const answerScore = faq.answer.toLowerCase().includes(searchTerm) ? 1 : 0;
    return questionScore + answerScore;
  };

  const filteredFaqs = faqs
    .map((faq) => ({ ...faq, score: getSearchScore(faq, searchTerm) }))
    .filter((faq) => faq.score > 0)
    .sort((a, b) => b.score - a.score);

  return (
    <>
      <Helmet>
        <title>FitFull - {t('navigation.faq')}</title>
      </Helmet>
      <section className="sectionWithDefaultPadding centeredSection" id="faqSection">
        <div className='pageTitle'>
            <h2>{t('faq.pageTitle')}</h2>
            <h4>{t('faq.description')}</h4>
        </div>
        


        <input
            type="text"
            placeholder={t('faq.search')}
            value={searchTerm}
            onChange={handleSearchChange}
            className='faqList'
          />
        <div className="faqList">

        {filteredFaqs.map((faq) => (
          
            <>
            <div
            key={faq.id}
            className={`faqItem ${faq.isOpen ? 'open' : ''}`}
            >
            <div className="faqQuestion"             onClick={() => handleFaqClick(faq.id)}>
                <p>{faq.question}</p>
                <img
                className={`faqDropdownIcon ${faq.isOpen ? 'active' : ''}`}
                src={dropdownOpen}
                alt=""
                />
            </div>
            {faq.isOpen && (
                <p className="faqAnswer" dangerouslySetInnerHTML={createMarkup(faq.answer)}></p>
            )}
            
            </div>
            <div className='horizontal_line' />
            </>
        ))}
        </div>
      </section>
    </>
  );
}
