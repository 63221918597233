import React from 'react';
import { useTranslation } from 'react-i18next';
import germany from '../assets/images/flag_of_germany.svg'
import uk from '../assets/images/flag_of_gb.png'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div id="changeLanguages">
      <a
        onClick={() => changeLanguage('en')}
        className={i18n.language === 'en' ? 'activeLanguage' : ''}
      >
        <img src={uk} alt="flag of uk" /> English
      </a>
      <a
        onClick={() => changeLanguage('de')}
        className={i18n.language === 'de' ? 'activeLanguage' : ''}
      >
        <img src={germany} alt="flag of germany" /> German
      </a>
    </div>
  );
};

export default LanguageSwitcher;
