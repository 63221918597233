
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Pricing from "./pages/pricing/Pricing";
import Faq from "./pages/faq/Faq";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Imprint from "./pages/imprint/Imprint";
import { HelmetProvider } from 'react-helmet-async';
import DataPrivacyPolicy from "./pages/data-privacy-policy/DataPrivacyPolicy";
import Agbs from "./pages/agbs/Agbs";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

export default function App() {
  const helmetContext = {};

  return (
    <>
    <BrowserRouter>
      <ScrollToTop />
      <HelmetProvider context={helmetContext}>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
          {/**<Route path="/features" element={<Features />} /> */}
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/data-privacy-policy" element={<DataPrivacyPolicy />} />
          <Route path="/agbs" element={<Agbs />} />
        </Routes>   
             
        <Footer />
        </HelmetProvider>
    </BrowserRouter>
    </>
  );
}


