import { useTranslation } from "react-i18next";

export default function DataPrivacyPolicy() {
    const { t } = useTranslation();
 
    return (
        <>
        <section className="sectionWithDefaultPadding data_screen">
            <h3>{t('navigation.policy')}</h3>
            <p>{t('data_privacy_policy.last_change')}: 25.02.2024</p>
            <div className='data_rights_container'>
                <h4>1 {t('data_privacy_policy.scope_of_application')}</h4>
                <p>{t('data_privacy_policy.scope_of_application_text')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>2 {t('data_privacy_policy.categories_of_personal_data')}</h4>
                <p>{t('data_privacy_policy.categories_of_personal_data_text')}</p>
                <div>
                  <h5>{t('data_privacy_policy.account_data')}:</h5>
                  <p>{t('data_privacy_policy.categories_of_personal_data_account')}</p>
                </div>
                <div>
                  <h5>{t('data_privacy_policy.usage_data')}:</h5>
                  <p>{t('data_privacy_policy.categories_of_personal_data_usage')}</p>
                </div>
                <div>
                  <h5>{t('data_privacy_policy.health_data')}:</h5>
                  <p>{t('data_privacy_policy.categories_of_personal_data_health')}</p>
                </div>
                <div>
                  <h5>{t('data_privacy_policy.fitness_data')}:</h5>
                  <p>{t('data_privacy_policy.categories_of_personal_data_fitness')}</p>
                </div>
            </div>
            <div className='data_rights_container'>
                <h4>3 {t('data_privacy_policy.purposes_of_processing')}</h4>
                <p>{t('data_privacy_policy.purposes_of_processing')}</p>
                <div>
                  <h5>{t('data_privacy_policy.purposes_of_processing_text')}:</h5>
                  <p>{t('data_privacy_policy.purposes_of_processing_account')}</p>
                </div>
                <div>
                  <h5>{t('data_privacy_policy.usage_data')}:</h5>
                  <p>{t('data_privacy_policy.purposes_of_processing_usage')}</p>
                </div>
                <div>
                  <h5>{t('data_privacy_policy.health_data')}:</h5>
                  <p>{t('data_privacy_policy.purposes_of_processing_health')}</p>
                </div>
                <div>
                  <h5>{t('data_privacy_policy.fitness_data')}:</h5>
                  <p>{t('data_privacy_policy.purposes_of_processing_fitness')}</p>
                </div>
            </div>
            <div className='data_rights_container'>
                <h4>4 {t('data_privacy_policy.legal_base_of_processing')}</h4>
                <p>{t('data_privacy_policy.legal_base_of_processing_text')}</p>
                <div>
                    <h5>{t('data_privacy_policy.legal_base_of_processing_01_title')}</h5>
                    <p>{t('data_privacy_policy.legal_base_of_processing_01_description')}</p>
                </div>
                <div>
                    <h5>{t('data_privacy_policy.legal_base_of_processing_02_title')}</h5>
                    <p>{t('data_privacy_policy.legal_base_of_processing_02_description')}</p>
                </div>
                <div>
                    <h5>{t('data_privacy_policy.legal_base_of_processing_03_title')}</h5>
                    <p>{t('data_privacy_policy.legal_base_of_processing_03_description')}</p>
                </div>
                <div>
                    <h5>{t('data_privacy_policy.legal_base_of_processing_04_title')}</h5>
                    <p>{t('data_privacy_policy.legal_base_of_processing_04_description')}</p>
                </div>
            </div>
            <div className='data_rights_container'>
                <h4>5 {t('data_privacy_policy.receipients')}</h4>
                <p>{t('data_privacy_policy.receipients_text')}</p>
                <div>
                  <h5>{t('data_privacy_policy.receipients_01_title')}:</h5>
                  <p>{t('data_privacy_policy.receipients_01_description')}</p>
                </div>
                <div>
                  <p>{t('data_privacy_policy.receipients_01_example_title')}</p>
                  <p>{t('data_privacy_policy.receipients_01_example_description')}</p>
                </div>
                <div>
                    <h5>{t('data_privacy_policy.receipients_02_title')}:</h5>
                    <p>{t('data_privacy_policy.receipients_02_description')}</p>
                </div>
                <div>
                    <p>{t('data_privacy_policy.receipients_02_example_title')}</p>
                    <p>{t('data_privacy_policy.receipients_02_example_description')}</p>
                </div>
                <div>
                    <h5>{t('data_privacy_policy.receipients_03_title')}:</h5>
                    <p>{t('data_privacy_policy.receipients_03_description')}</p>
                </div>
                <div>
                    <p>{t('data_privacy_policy.receipients_03_example_title')}</p>
                    <p>{t('data_privacy_policy.receipients_03_example_description')}</p> 
                </div>
            </div>
            <div className='data_rights_container'>
                <h4>6 {t('data_privacy_policy.duration_of_storage')}</h4>
                <p>{t('data_privacy_policy.duration_of_storage_text')}</p>
                <p>{t('data_privacy_policy.duration_of_storage_list_title')}</p>
                <li>{t('data_privacy_policy.duration_of_storage_list_01')}</li>
                <li>{t('data_privacy_policy.duration_of_storage_list_02')}</li>
                <li>{t('data_privacy_policy.duration_of_storage_list_03')}</li>
            </div>
            <div className='data_rights_container'>
                <h4>7 {t('data_privacy_policy.cross_border')}</h4>
                <p>{t('data_privacy_policy.cross_border_text')}</p>                
            </div>
            <div className='data_rights_container'>
                <h4>8 {t('data_privacy_policy.your_rights')}</h4>
                <p>{t('data_privacy_policy.your_rights_text')}</p>                
            </div>
            <div className='data_rights_container'>
                <h4>9 {t('navigation.contact')}</h4>
                <p>{t('data_privacy_policy.contact_text')}</p>
                <div className="row_container">
                  <p>E-Mail:</p>
                  <p>support@fitfull.ch</p>
                </div>
                <div className="row_container">
                  <p>Post:</p>
                  <p>FitFull KLG <br/>
                    Sagastrass 2 <br/>
                    7214 Grüsch
                  </p>
                </div>
            </div>
            <div className='data_rights_container'>
                <h4>10 {t('data_privacy_policy.change_of_privacy_policy')}</h4>
                <p>{t('data_privacy_policy.change_of_privacy_policy_title')}</p>
                <p>{t('data_privacy_policy.change_of_privacy_policy_description')}</p>
            </div>
            <div className='data_rights_container'>
                <h4>11 {t('data_privacy_policy.compliance_new_swiss')}</h4>
                <p>{t('data_privacy_policy.compliance_new_swiss_text')}</p>
                <li>{t('data_privacy_policy.compliance_new_swiss_description')}</li>
            </div>
        </section>
        </>
    )
}