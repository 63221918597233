import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import arrowsDown from '../../assets/icons/arrows_down.svg';
import landingPageMockup from '../../assets/images/landingPage_mockup.png';
import featureTeam from '../../assets/images/home_features_teams.svg';
import featureLogo from '../../assets/images/home_features_logo.svg';
import featureMockup from '../../assets/images/home_features_mockup.svg';
import featureSmartwatches from '../../assets/images/home_features_smartwatches.svg';
import featureHand from '../../assets/images/home_features_hand.svg';
import featureAnalytics from '../../assets/images/home_features_analytics.svg';
import exampleReference from '../../assets/images/home/example_image.png';
import qrCodeImageApple from '../../assets/images/qrCodes/apple.png';
import qrCodeImageAndroid from '../../assets/images/qrCodes/android.png';

import './Home.css';
import { Link, NavLink } from 'react-router-dom';

export default function Home() {
  const { t } = useTranslation();
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [showCircle, setShowCircle] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMouseX(e.clientX);
      setMouseY(e.clientY);

      const qrCodesContainer = document.getElementById('qrcodes');
      if (qrCodesContainer) {
        const rect = qrCodesContainer.getBoundingClientRect();
        const isInContainer =
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom;

        setShowCircle(isInContainer);
      }
    };

    document.body.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.body.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  return (
    <>
      <Helmet>
        <title>FitFull - Train smarter</title>
      </Helmet>
      <section id="landingPageSection" className='sectionPaddingTop'>
                <div id="landingPageText">
                    <h1>{t('home.pageTitle1')} <br /> {t('home.pageTitle2')} </h1>
                    <p>{t('home.landingPageDescription')}</p>
                    <div className="navButton callToActionMobile">
                        <a className="defaultButton darkButton" href='https://app.fitfull.ch/login'>
                        {t('default.signin')}
                        </a>
                        <a className="defaultButton blueButton" href='https://app.fitfull.ch/register'>
                        {t('default.register')}
                        </a>
                    </div>
                    <a href="#homePlatformSection"><img src={arrowsDown} alt="arrow down"/></a>
                </div>
                <img id='landingPageMockup' src={landingPageMockup} alt="mockup"/>
            </section>
            <section className='blackBackground' id="homePlatformSection">
                <div id="platformTitles">
                    <p>WEB IOS <span>ANDROID</span> WEB IOS ANDROID WEB <span>IOS</span> ANDROID WEB IOS ANDROID WEB <span>IOS</span> ANDROID WEB IOS ANDROID WEB IOS ANDROID</p>
                    <p>OS ANDROID WEB IOS ANDROID WEB IOS <span>ANDROID</span> WEB IOS ANDROID <span>WEB</span> IOS ANDROID WEB IOS ANDROID WEB IOS ANDROID</p>
                    <p>B IOS ANDROID WEB <span>IOS</span>  ANDROID WEB IOS ANDROID WEB IOS ANDROID WEB <span>IOS</span> ANDROID WEB IOS ANDROID WEB IOS ANDROID</p>
                    <p><span>WEB</span> IOS ANDROID WEB IOS ANDROID WEB IOS ANDROID <span>WEB</span> IOS ANDROID WEB IOS <span>ANDROID</span> WEB IOS ANDROID WEB IOS ANDROID</p>
                    <p>DROID WEB IOS ANDROID <span>WEB</span> IOS ANDROID WEB IOS ANDROID WEB <span>IOS</span> ANDROID WEB IOS ANDROID WEB IOS ANDROID</p>
                    <p>IOS ANDROID <span>WEB</span> IOS ANDROID <span>IOS</span> ANDROID WEB IOS WEB <span>IOS</span> ANDROID WEB IOS ANDROID WEB IOS ANDROID</p>
                </div>
                <div id="allPlatformsDiv">
                    <h1>{t('home.available.titleTop')} <br/> {t('home.available.titleBottom')}</h1>
                    <p className='textOnBlackSection'>{t('home.available.description')}</p>
                </div>
                <h4>{t('home.available.task')}</h4>    
         
            </section>
            <div
                id="qrcodes"
                className={`qrCodes ${showCircle ? 'circle-mode' : ''}`}
                onMouseMove={(e) => {
                    const x = e.clientX - e.currentTarget.getBoundingClientRect().left;
                    const y = e.clientY - e.currentTarget.getBoundingClientRect().top;
                    setMouseX(x);
                    setMouseY(y);
                }}
            >   
            <div className='qrCodesImages'>
                {/**<a href='#' className='qrCodesImage'>
                    <img className='qrCodesImage_hide' src={qrCodeImageHide} alt="" />
                    <p>Launch coming soon!</p>
                </a> */}
                <a href="https://apps.apple.com/ch/app/fitfull/id6497330438" className='qrCodesImage' target="_blank">
                    <img src={qrCodeImageApple} alt="" />
                    <p>Apple App Store</p>
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.fitfull.android' className='qrCodesImage' id='googlePlayStore' target="_blank">
                    <img src={qrCodeImageAndroid} alt="" />
                    <p>Google Play Store</p>
                </a>           
            </div>                
                <div className="cursor" style={{ left: mouseX, top: mouseY }}></div>
            </div>
            <section id="homeFeatureSection">
                <div className='defaultSitePadding' id='homeFeaturesTitle'>
                    <h1>{t('home.features.titleTop')} <br/> {t('home.features.titleBottom')}</h1>                    
                </div>
                <div id='homeFeaturesDiv'>
                    <div className='homeFeatureDiv'>
                        <img src={featureTeam} alt="team" />
                    </div>
                    <div className='homeFeatureSmall'>
                        <div className='homeFeatureDiv'>
                            <img src={featureLogo} alt="logo" />
                        </div>
                        <div className='homeFeatureDiv homeFeaturesDarkBackground'>
                            <img src={featureMockup} alt="mockup" />
                        </div>  
                    </div>
                    <div className='homeFeatureDiv homeFeaturesDarkBackground'>
                        <img src={featureSmartwatches} alt="Smartwatches" />
                    </div>
                    <div className='homeFeatureDiv'>
                        <img src={featureHand} alt="Hand" />
                    </div>
                    <div className='homeFeatureDiv'>
                        <img src={featureAnalytics} alt="Analytics" />
                    </div>
                </div>
            </section>
            <section id='homeReferencesSection' className='defaultSitePadding'>
                <h3>{t('home.customersTitle')}</h3>
                
                <div>
                    <img src={exampleReference} alt="example" />
                    <img src={exampleReference} alt="example" />
                    <img src={exampleReference} alt="example" />
                    <img src={exampleReference} alt="example" />
                    <img src={exampleReference} alt="example" />
                </div>
            </section>
            <section  id='homeActionSection'>
                <div className='homeActionDiv'>
                    <h3>{t('home.chanceTitle')}</h3>
                    <p>{t('home.chanceDescription')}</p>
                </div>
                <div className='homeActionDiv'>
                    <h3>{t('home.testTitle')}</h3>
                    <p>{t('home.testDescription')}</p>
                    <a className="defaultButton blueButton" href='https://app.fitfull.ch/register'>
                        {t('default.register')}
                    </a>
                </div>
            </section>      
    </>
  );
}