import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo.svg';
import './Footer.css';
import { NavLink } from "react-router-dom";
import LanguageSwitcher from "../../functions/LanguageSwitcher";
import { FaFacebook, FaInstagram, FaLinkedinIn, FaTiktok, FaYoutube } from 'react-icons/fa';



export default function Footer() {
    const { t } = useTranslation();
    return (
        <footer>
            <div className='footer_top'>
                <NavLink to="/"><img id="logo" src={logo} alt=""/></NavLink>
                <div>
                    <p>© FitFull {new Date().getFullYear()}</p>
                </div>
                <a href="mailto:support@fitfull.ch">support@fitfull.ch</a>
                <LanguageSwitcher />

                <div>
                    <h6>{t('footer.content')}</h6>
                    {/**<NavLink to="/features">{t('navigation.features')}</NavLink> */}
                    <NavLink to="/pricing">{t('navigation.pricing')}</NavLink>
                    <NavLink to="/faq">{t('navigation.faq')}</NavLink>
                    <NavLink to="/about">{t('navigation.aboutus')}</NavLink>
                    <NavLink to="/contact">{t('navigation.contact')}</NavLink>
                </div>
                <div id="footerRights">
                    <h6>{t('footer.rights')}</h6>
                    <NavLink to="/imprint">{t('navigation.imprint')}</NavLink>
                    <NavLink to="/data-privacy-policy">{t('navigation.policy')}</NavLink>
                    <NavLink to="/agbs">{t('navigation.agbs')}</NavLink>
                </div>
            </div>
            <div className='footer_bottom'>
                <a className="footer_bottom_link" target='_blank' rel='noopener noreferrer'  href='https://www.instagram.com/fitfull.ch'>
                    <FaInstagram className='social_media_icon' size={20} />
                </a>
                <a className="footer_bottom_link" target='_blank' rel='noopener noreferrer'  href='https://www.tiktok.com/@fitfull.ch'>
                    <FaTiktok className='social_media_icon' size={20}/>
                </a>
                <a className="footer_bottom_link" target='_blank' rel='noopener noreferrer'  href='https://www.facebook.com/FitFull.KLG'>
                    <FaFacebook className='social_media_icon' size={20}/>
                </a>
                <a className="footer_bottom_link" target='_blank' rel='noopener noreferrer'  href='https://www.youtube.com/@FitFullYT'>
                    <FaYoutube className='social_media_icon' size={20}/>
                </a>
                <a className="footer_bottom_link" target='_blank' rel='noopener noreferrer'   href='https://www.linkedin.com/company/fitfull/'>
                    <FaLinkedinIn className='social_media_icon' size={20}/>
                </a>
            </div>
            
        </footer>
    );
}