import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo.svg';
import { NavLink } from 'react-router-dom';
import './Header.css';
import { useState, useEffect } from 'react';
import HamburgerMenu from '../hamburgerMenu/HamburgerMenu';
export default function Header() {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  


  return (
    <div className='header_container'>
    {/**<ComingSoonBanner /> */}
    <header>
      <NavLink to="/" className="logo_container">
        <img id="logo" src={logo} alt="" />
      </NavLink>
      {windowWidth <= 1200 ? (
        <HamburgerMenu t={t} />
      ) : (
        <>
          <nav>
            {/**
             * <NavLink activeClassName="active" to="/features">
              {t('navigation.features')}
            </NavLink>
             */}
            <NavLink activeClassName="active" to="/pricing">
              {t('navigation.pricing')}
            </NavLink>
            <NavLink activeClassName="active" to="/faq">
              {t('navigation.faq')}
            </NavLink>
            <NavLink activeClassName="active" to="/about">
              {t('navigation.aboutus')}
            </NavLink>
            <NavLink activeClassName="active" to="/contact">
              {t('navigation.contact')}
            </NavLink>
          </nav>
          <div className="navButton">
            <a className="defaultButton lightButton" href='https://app.fitfull.ch/login'>
              {t('default.signin')}
            </a>
            <a className="defaultButton blueButton" href='https://app.fitfull.ch/register'>
              {t('default.register')}
            </a>
          </div>
        </>
      )}
    </header>
    </div>
  );
}
